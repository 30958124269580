.component__footer{
    background-color: var(--color-bg1);
    /* height: 150px; */
    margin-top: 50px;
    padding: 50px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: var(--border);
    /* width: 100%; */
}
.component__footer-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    /* border-left: 1px solid rgba(255, 255, 255, 0.384); */
    /* border-right: 1px solid rgba(255, 255, 255, 0.384); */
    /* width: 98%; */
    /* margin: auto; */
}
.component__footer-socials{
    font-size: 20px;
    color: var(--color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}
.component__footer-social-each{
    transition: 0.5s;
    cursor: pointer;
}
.component__footer-social-each:hover{
    color: var(--color-primary2);
}
.component__footer-copyright{
    color: var(--color-primary2);
    font-size: 16px;
}


@media screen and (max-width: 800px) {
    .component__footer-content{
        flex-direction: column;
    }
    .component__footer-socials{
        font-size: 25px;
        gap: 20px;
    }
    .component__footer-copyright{
        display: flex;
        justify-content: center;
        font-size:14px;
    }
}

@media screen and (max-width: 450px) {
    .component__footer{
        padding: 20px 4%;
        
    }
    .component__footer-socials{
        font-size: 25px;
        gap: 30px;
    }
    .component__footer-copyright{
        font-size:12px;
    }
}