.component__skills{
    color: white; 
    /* display: flex;
    flex-direction: column;
    justify-content: center;  */
    margin: auto;
    padding: 50px 0 0 0;
}
.component__skills h1{
    text-align: center;
    color: var(--color-primary);
    font-size: 30px;
}
.component__skills-content{
    display: flex;
    flex-direction: row;
    /* margin: auto 20px; */
    background-color: rgba(255, 255, 255, 0.050);
    padding: 30px;
}
.component__skills-content-swiper{
    
}
.swiperSlide{
    margin: auto;
    display: flex;
    justify-content: center;
}
.techIcon{
    width: 50px;
}
.component__skills-frontend{
    background-color: rgba(255, 255, 255, 0.050);
    /* width: 50%; */
    border-radius: 20px;
    margin: 0 5%;
    flex: 1;
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    padding: 20px;
    gap: 20px;
    transition: 0.5s;
    /* justify-content: center; */
    /* align-content: center; */
}
.component__skills-frontend:hover{
    background-color: rgba(255, 255, 255, 0);
    border: var(--border);
    transition: 0.5s;
}
.component__skills-frontend-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.component__skills-frontend h2{
    text-align: center;
    font-size: 25px;
    color: var(--color-text);
    margin-bottom: 20px;
}
.component__skills-frontend-each{
    /* margin: auto; */
    width: 100%;
}
.component__skills-frontend-each h3{
    font-size: 18px;
    color: var(--color-primary);
}
.component__skills-frontend-each-bar{
    width: 100%;
    height: 20px;
    border:1px white solid;
    border-radius: 30px;

}
.component__skills-frontend-each-barloadhtml{
    width: 90%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}
.component__skills-frontend-each-barloadcss{
    width: 85%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}
.component__skills-frontend-each-barloadjs{
    width: 69%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}
.component__skills-frontend-each-barloadreact{
    width: 65%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}
.component__skills-frontend-each-barloaddi{
    width: 90%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}

.component__skills-frontend-each-barloadmp{
    width: 70%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}
.component__skills-frontend-each-barloadpiano{
    width: 70%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}
.component__skills-frontend-each-barloadguitar{
    width: 85%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}
.component__skills-frontend-each-barloadbass{
    width: 70%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}
.component__skills-frontend-each-barloadspanish{
    width: 80%;
    height: 20px;
    border-radius: 30px;
    background-color: var(--color-text-light);
}


@media screen and (max-width:800px) {
    .component__skills h1{
        font-size: 25px;
    }
    /* .component__skills-frontend-content{
        grid-template-columns: repeat(1, 1fr);
    } */
    .component__skills-frontend h2{
        font-size: 20px;
    }
    .component__skills-frontend-each h3{
        font-size: 16px;
    }
    
}
@media screen and (max-width:450px) {
    .component__skills h1{
        font-size: 24px;
    }
    .techIcon{
        width: 40px;
    }
}