.component__about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 5% 0 5%;
    
}
.component__about-title{
    
}
.component__about-title h1{
    color: var(--color-primary);
    font-size: 30px;
    text-align: center;
}
/* .component__about-title::after{
    content: '';
    height: 2px;
    width: 40%;
    background-color: var(--color-text-light);
    margin: 0 30% ;
    display: flex;
    transition: 0.5s;
    margin-bottom: 30px;
} */
.component__about-content{
    display: flex;
}
.component__about-img{
    background-color: rgba(255, 255, 255, 0.137);
    border-radius: 20%;
    width: 300px;
    height: 340px;
    margin: auto;
}
.component__about-img img{
    border-radius: 20%;
    width: 300px;
    transform: rotate(30deg);
    transition: 0.5s;
    filter: saturate(90%);
}
.component__about-img img:hover{
    transform: rotate(0deg);
    filter: saturate(150%);
}
.component__about-content-exp-par{
    display: flex;
    padding: 0 0 0 10%;
    flex-direction: column;
    flex: 1;
}
.component__about-experience{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    gap: 10%;
    margin-bottom: 5%;
    padding: 0 20px 0 20px;
}
.component__about-experience-each{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: var(--color-bg);
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgb(105, 100, 100);
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid transparent;
    transition: 0.3s;
    cursor: default;
}
.component__about-experience-each:hover{
    background-color: rgba(255, 255, 255, 0.050);
    box-shadow: 0px 0px 0px rgb(105, 100, 100);
    border: 1px solid var(--color-text);
}
.component__about-experience-each h2{
    color: var(--color-text);
    font-size: 25px;
}
.component__about-experience-each h4{
    color: var(--color-primary2);
    font-size: 16px;
}
.component__about-content-paragraph{
    margin: auto;
    /* text-align: right; */
    justify-content: space-evenly;
    background-color: rgba(255, 255, 255, 0.050);
    border: 1px solid transparent;
    padding: 20px;
    border-radius: 20px;
    transition: 0.5s;
}
.component__about-content-paragraph:hover{
    border: var(--border);
    justify-content: space-evenly;
    background-color: unset;
    transition: 0.5s;
}
.component__about-content-paragraph p{
    color: var(--color-primary2);
    font-size: 16px;
    
}
.component__about-content-contact{
    display: flex;
    justify-content: center;
}




@media screen and (max-width:1024px) {
    .component__about-img{
        width: 200px;
        height: 225px;
    }
    .component__about-img img{
        border-radius: 20%;
        width: 200px;
    }
    
}

@media screen and (max-width:850px) {
    .component__about-content{
        display: flex;
        flex-direction: column;
    }
    .component__about-img{
        margin-bottom: 30px;
    }
    .component__about-content-exp-par{
        display: flex;
        padding: 0 0 0 0%;
        flex-direction: column;
        flex: 1;
    }
}

@media screen and (max-width:800px) {
    .component__about-title h1{
        font-size: 25px;
    }
    .component__about-experience-each h2{
        font-size: 20px;
    }
    .component__about-experience-each h4{
        font-size: 14px;
    }       
    .component__about-content-paragraph p{
        font-size: 14px;
    }
    /* .component__about-content-contact button{
        font-size: 20px;
    } */
}

@media screen and (max-width:450px) {
    .component__about-title h1{
        font-size: 24px;
    }
    .component__about-img{
        width: 150px;
        height: 170px;
    }
    .component__about-img img{
        border-radius: 20%;
        width: 150px;
    }
    .component__about-experience{
        display: flex;
        flex-direction: column;
        margin-bottom: 10%;
        padding: 0 20px 0 20px;
    }
    .component__about-content-paragraph{
        padding: 10px;
    }
    .component__about-content-paragraph p{
        /* text-align: center; */
        padding: 10px;
    }
}