.component__portfolio{
    padding-top: 50px;
}
.component__portfolio h1{
    color:var(--color-primary);
    text-align: center;
    font-size: 30px;
    
}
.component__portfolio-content-webdev h2{
    color:var(--color-text);
    text-align: center;
    font-size: 25px;
    padding-bottom: 20px;
    margin-top: -20px;
}
.component__portfolio-content-webdev{
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
}
.component__portfolio-content-webdev-all{
    display: grid;
    /* flex-direction: row; */
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    /* gap: 20px; */
    /* flex-wrap: wrap; */
}
.check{
    background-color: var(--color-text);
}
.component__portfolio-content-webdev-each{
    background-color: rgba(255, 255, 255, 0.050);
    width: 80%;
    display: flex;
    margin: 0 auto;
    gap: 10px;
    flex-direction: column;
    /* justify-content: center; */
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 20px;
    margin-bottom: 20px;
    transition: 0.5s;
}
.component__portfolio-content-webdev-each:hover{
    background-color: rgba(255, 255, 255, 0);
    border: var(--border);
    transition: 0.5s;
}
.component__portfolio-content-webdev-each img{
    width: 100%;
    border-radius: 20px;
    margin: auto;
}
.component__portfolio-content-webdev-each-technologies{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
}
.tech-icon{
    color: var(--color-light);
    font-size: 30px;
}
.component__portfolio-content-webdev-each-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.component__portfolio-content-webdev-each h3{
    color: white;
    text-align: center;
    font-size: 25px;
    /* background-color: var(--color-bg1); */
    border-radius: 5px;
    margin: auto;
}
.component__portfolio-content-webdev-each p{
    color: var(--color-primary2);
    text-align: center;
    font-size: 16px;
    margin: auto;
    /* padding: 5px; */

    /* height:140px; */
}
.component__portfolio-content-webdev-each button{
    font-size: 25px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    /* width:200px; */
    margin: auto;
    background-color: var(--color-text);
    color:white ;
    transition: 0.5s;
}
.component__portfolio-content-webdev-each button:hover{
    background-color: var(--color-text-light);
}

.component__portfolio-content-illustration-all{
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 20px 0 20px;
}

.component__portfolio-content-illustration h2{
    color:var(--color-text);
    text-align: center;
    font-size: 25px;
    padding-bottom: 20px;
    padding-top: 40px;
}

.component__portfolio-content-illustration-each{
    background-color: rgba(255, 255, 255, 0.050);
    /* width: 60%; */
    /* height */
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    transition: 0.5s;
}
.component__portfolio-content-illustration-each:hover{
    transition:0.5s;
    background-color: rgba(255, 255, 255, 0);
    border: var(--border);
}
.component__portfolio-content-illustration-each img{
    width: 95%;
    border-radius: 20px;
    margin: auto;
}

@media screen and (max-width:800px) {
    .component__portfolio h1{
        font-size: 25px;
    }
    .component__portfolio-content-webdev h2{
        font-size: 20px;
    }
    .component__portfolio-content-webdev-all{
        display: grid;
        /* flex-direction: row; */
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
    .component__portfolio-content-webdev-each h3{
        font-size: 20px;
    }
    
    .component__portfolio-content-webdev-each button{
        font-size: 25px;
    }
    .component__portfolio-content-illustration-all{
        display: grid;
        flex-direction: row;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        padding: 0 20px 0 20px;
    }
    .component__portfolio-content-illustration h2{
        font-size: 20px;
    }

    .component__portfolio-content-webdev-each p{
        height:unset;
    }
    .component__portfolio-content-webdev-each button{
        font-size: 20px;
    }
    .component__portfolio-content-illustration-each{
        width: 90%;
        padding: 10px;
    }
    .component__portfolio-content-illustration-each img{
        width: 100%;
    }
}

@media screen and (max-width:450px) {
    .component__portfolio{
        padding: 50px 10px 0 10px ;
    }
    .component__portfolio h1{
        font-size: 24px;
    }
    .component__portfolio-content-webdev h2{
        font-size: 18px;
    }
    .component__portfolio-content-webdev-all{
        display: grid;
        /* flex-direction: row; */
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
    .component__portfolio-content-webdev-each p{
        font-size: 14px;
    }
    .component__portfolio-content-webdev-each{
        width: 90%;
        padding: 10px;
    }
    .component__portfolio-content-webdev-each img{
        width: 100%;
        border-radius: 10px;
    }
}