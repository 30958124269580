.component__header{
    display: flex;
    /* height: 200vh; */
    /* background-color: black; */
    padding-top: 100px;
    /* border-bottom: 2px solid var(--color-light); */
}
.component__header-intro{
    /* flex: 1; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 5%;
}
.component__header-intro-about h2{
    color: var(--color-primary);
    font-size: 25px;
    cursor: default;
}
.component__header-intro-about h2 span{
    font-size: 50px;
    color: var(--color-primary);
}
.component__header-intro-about h3{
    color: var(--color-light);
    font-size: 50px;
    margin-bottom: 10px;
    font-family: 'Indie Flower', cursive;
    cursor: default;
    transition: 0.5s;
}
.component__header-intro-about h3:hover{
    color: var(--color-text);
}

.component__header-intro-about p{
    color: var(--color-primary2);
    font-size: 18px;
}

.component__header-intro-socials{
    font-size: 30px;
    color: var(--color-text-light);
    margin-top: 20px;
    display: flex;
    gap: 20px;
}
.components__header-intro-social-each{
    transition: 0.5s;
    cursor: pointer;
}

.components__header-intro-social-each:hover{
    color:var(--color-primary);
}

.component__header-pic{
    /* flex: 1; */
    display: flex;
    justify-content: center;
}
.component__header-pic img{
    width: 500px;
}

@media screen and (max-width:1024px) {
    .component__header-intro-about h2{
        font-size: 20px;
    }
    .component__header-intro-about h2 span{
        font-size: 40px;
    }
    .component__header-intro-about h3{
        font-size: 50px;
    }
}
@media screen and (max-width:800px) {
    .component__header{
        display: flex;
        
    }
    .component__header-pic img{
        width: 400px;
    }
    .component__header-intro-about h2{
        font-size: 20px;
    }
    .component__header-intro-about h2 span{
        font-size: 30px;
    }
    .component__header-intro-about h3{
        font-size: 40px;
    }
    .component__header-intro-about p{
        font-size: 16px;
    }
}
@media screen and (max-width:800px) {
    .component__header{
        display: flex;
        flex-direction: column-reverse;
        padding-top: 40px;
    }
    .component__header-intro{
        /* flex: 1; */
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 0%;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: center;
    }
    .component__header-pic img{
        width: 300px;
    }
    .component__header-intro-about h2{
        text-align: center;
        font-size: 20px;
    }
    .component__header-intro-about h2 span{
        text-align: center;
        font-size: 30px;
    }
    .component__header-intro-about h3{
        text-align: center;
        font-size: 40px;
    }
    .component__header-intro-about p{
        text-align: center;
        font-size: 16px;
    }
    .component__header-intro-socials{
        font-size: 30px;
        color: var(--color-light);
        margin-top: 20px;
        display: flex;
        justify-content: center;
        gap: 20px;
    }
}

@media screen and (max-width:800px) {
    .component__header{
        display: flex;
        flex-direction: column-reverse;
        padding-top: 40px;
    }
    .component__header-intro{
        /* flex: 1; */
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 0%;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: center;
    }
    .component__header-pic img{
        width: 300px;
    }
    .component__header-intro-about h2{
        font-size: 18px;
    }
    .component__header-intro-about h2 span{
        font-size: 25px;
    }
    .component__header-intro-about h3{
        font-size: 35px;
    }
    .component__header-intro-about p{
        font-size: 14px;
    }
}