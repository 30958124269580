.component__contact{
    padding-top: 50px;
}
.component__contact h1{
    color: var(--color-primary);
    font-size: 30px;
    text-align: center;
}
form{
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    /* gap: 20px; */
    /* background-color: rgba(255, 255, 255, 0.050); */
    /* margin: auto 20%; */
    /* border-radius: 20px; */
}
form input{
    height: 35px;
    width: 50%;
    margin: 0 auto 20px;
    border-radius: 10px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.384);
    color: white;
    font-size: 20px;
    background-color:unset;
    padding: 5px 0 5px 10px;
    font-family: 'Poppins', sans-serif;
}
form textarea{
    height: 200px;
    width: 50%;
    margin: auto;
    resize: none;
    border-radius: 10px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.384);
    color: white;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0);
    padding: 5px 0 0 10px; 
    font-family: 'Poppins', sans-serif;
}

/* .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
} */

.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media screen and (max-width:800px) {
    .component__contact h1{
        font-size: 25px;
    }
    form input{
        height: 30px;
        width: 80%;
        font-size: 18px;
    }
    form textarea{
        height: 150px;
        width: 80%;
        font-size: 18px;
    }
}

@media screen and (max-width:450px) {
    .component__contact h1{
        font-size: 24px;
    }
    form input{
        height: 30px;
        width: 90%;
        font-size: 16px;
    }
    form textarea{
        height: 150px;
        width: 90%;
        font-size: 16px;
    }
    /* form button{
        font-size: 20px;
    } */
}