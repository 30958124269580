@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Manrope:wght@300;400;500;600&&family=Mynerve&family=Oswald:wght@400;500;600&family=Poppins:wght@300;400;500;600&display=swap');

*{
  margin: 0;
  padding: 0;
}
:root{
  --color-bg: #08080f;
  --color-bg1: #08080fd0;
  --color-text: #246dff;
  --color-text-light: #6796f3;
  --color-primary: #f0f0f0;
  --color-primary2: #b9b9b9;
  --color-light:rgb(52, 105, 250);
  --color-ligt:rgb(98, 224, 218);
  --color-button-color: rgb(218, 74, 55);
  --color-button-hover:rgb(245, 42, 35);
  --border: 1px solid rgba(255, 255, 255, 0.384);
}
body { 
  background-image: url('./assets/texture.png');
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  background-color: var(--color-bg);
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

.slashline{
  display: flex;
  gap: 2px;
  justify-content: center;
  margin-bottom: 50px;
}
.line{
  height: 2px;
  width: 50px;
  background-color: var(--color-text);
}
.slash{
  height: 2px;
  width: 10px;
  background-color: var(--color-text-light);
  transform: rotate(-40deg);

}

.button{
  font-size: 25px;
  padding: 8px 16px;
  border:none;
  border-radius: 10px;
  margin: 20px auto;
  background-color: var(--color-text);
  color:white  ;
  border: 1px transparent solid;
  cursor: pointer;
  transition: 0.2s;
}

.button:hover{
  border: 1px var(--color-text) solid;
  background-color: unset;
}

@media screen and (max-width:800px) {
  .slashline{
    display: flex;
    gap: 2px;
    justify-content: center;
    margin-bottom: 30px;
  }
  .component__about-content-contact button{
      font-size: 20px;
  }
}

